import { CheckCircle as CheckCircleIcon, Clear as ClearIcon, Delete as DeleteIcon } from '@mui/icons-material'
import {
  Button,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import { hasUserRight } from '@src/contexts/appContext '
import { MerchantDashboardUser } from '@src/data/types/MerchantDashboardUserSchema'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { EUserRights } from '@src/data/types/Constants'
import { formatDateTime } from '../containers/App/utils/Formatter'
import Footer from '../containers/ViewMerchant/components/footer'
import EditProfileDialog from '../containers/ViewMerchant/components/profileDialog'
import TableToolbar from '../containers/ViewMerchant/components/toolBar'
import DeleteUserDialog from './deleteUserDialog'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

type Props = {
  merchantId: string
  usersList: MerchantDashboardUser[]
  isPendingSearchUsers: boolean
}

const Users = ({ merchantId, usersList, isPendingSearchUsers }: Props) => {
  const { t } = useTranslation()
  const [editUser, setEditUser] = React.useState<MerchantDashboardUser | undefined>(undefined)
  const [openUserDialog, setOpenUserDialog] = React.useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)
  const [emailToDelete, setEmailToDelete] = React.useState('')
  const [filteredValue, setFilteredValue] = React.useState<string>('')

  const canDeleteUser = hasUserRight(EUserRights.canDeleteUser)

  const usersFiltered = useMemo(() => {
    if (!usersList) return []

    return usersList.filter(
      (user) =>
        user.email.toLowerCase().includes(filteredValue.toLowerCase()) ||
        user.firstName.toLowerCase().includes(filteredValue.toLowerCase()) ||
        user.lastName.toLowerCase().includes(filteredValue.toLowerCase()),
    )
  }, [usersList, filteredValue])

  const handleSearch = useCallback((value: string) => {
    setFilteredValue(value)
  }, [])

  const handleCloseProfile = (user?: MerchantDashboardUser) => {
    setEditUser(undefined)
    setOpenUserDialog(false)

    if (user) {
      const index = usersFiltered.findIndex((x) => x.email === user.email)

      if (index > -1) {
        usersFiltered.splice(index, 1, user)
      } else {
        usersFiltered.push(user)
      }
    }
  }

  const handleOpenDeleteDialog = useCallback((email: string) => {
    setOpenDeleteDialog(true)
    setEmailToDelete(email)
  }, [])

  const handleCloseDeleteDialog = useCallback(() => {
    setOpenDeleteDialog(false)
  }, [])

  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersFiltered.length) : 0

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <>
      <TableContainer component={Paper}>
        <TableToolbar handleAdd={setOpenUserDialog} handleSearch={handleSearch} />
        <Table sx={{ maxHeight: '25rem' }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t('common.fullName')}</StyledTableCell>
              <StyledTableCell>{t('common.state')}</StyledTableCell>
              <StyledTableCell align="right">{t('common.email')}</StyledTableCell>
              <StyledTableCell align="right">{t('common.language')}</StyledTableCell>
              <StyledTableCell align="right">{t('common.jobTitle')}</StyledTableCell>
              <StyledTableCell align="right">{t('common.lastConnection')}</StyledTableCell>
              <StyledTableCell align="right" />
            </TableRow>
          </TableHead>

          <TableBody>
            {isPendingSearchUsers ? (
              <TableRow>
                <TableCell colSpan={20} style={{ padding: 0 }}>
                  <LinearProgress style={{ height: '10px' }} />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {(rowsPerPage > 0
                  ? usersFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : usersFiltered
                ).map((row) => (
                  <TableRow key={row.email}>
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="body2"
                        component={Button}
                        onClick={() => {
                          setEditUser(row)
                        }}
                      >
                        {row.fullName}
                      </Typography>
                    </TableCell>
                    <TableCell align="left" sx={{ paddingLeft: '3.5%' }}>
                      {row.accountEnabled ? (
                        <Tooltip title={t('common.enable')}>
                          <CheckCircleIcon sx={{ color: 'green' }} />
                        </Tooltip>
                      ) : (
                        <Tooltip title={t('common.disable')}>
                          <ClearIcon sx={{ color: 'red' }} />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="right">{row.email}</TableCell>
                    <TableCell align="right">{row.language}</TableCell>
                    <TableCell align="right">{row.jobTitle}</TableCell>
                    <TableCell align="right">{formatDateTime(row.lastConnection)}</TableCell>
                    <TableCell align="right">
                      <Tooltip
                        title={canDeleteUser ? t('deleteDialog.dialogTitle') : t('deleteDialog.accessDeniedMessage')}
                      >
                        <span>
                          <IconButton
                            onClick={() => {
                              handleOpenDeleteDialog(row.email)
                            }}
                            disabled={!canDeleteUser}
                          >
                            <DeleteIcon sx={{ color: canDeleteUser ? 'red' : 'grey' }} />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </>
            )}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                count={usersFiltered.length}
                rowsPerPage={rowsPerPage}
                page={page}
                slotProps={{
                  select: {
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={Footer}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <EditProfileDialog
        key={editUser?.email}
        open={openUserDialog || editUser !== undefined}
        handleClose={handleCloseProfile}
        user={editUser}
        merchantId={merchantId}
      />

      <DeleteUserDialog
        open={openDeleteDialog}
        handleClose={handleCloseDeleteDialog}
        email={emailToDelete}
        merchantId={merchantId}
      />
    </>
  )
}

export default Users
