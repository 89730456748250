import { getUserToken } from '@src/data/providers/UserProvider'
import axios, { AxiosError } from 'axios'
import { reportErrorToServer } from './error-logger'

export function getStringArrayFromQueryString(
  urlParams: URLSearchParams,
  paramName: string,
  defaultValue: string[] = [],
): string[] {
  const value = decodeURIComponent(urlParams.get(paramName) ?? '')
  return !value ? defaultValue : value.split(',')
}

export function getNumberArrayFromQueryString(
  urlParams: URLSearchParams,
  paramName: string,
  defaultValue: number[] = [],
): number[] {
  const value = decodeURIComponent(urlParams.get(paramName) ?? '')
  return !value ? defaultValue : value.split(',').map((x) => +x)
}

export function getNumberFromQueryString(urlParams: URLSearchParams, paramName: string, defaultValue: number): number {
  const value = decodeURIComponent(urlParams.get(paramName) ?? '')
  return !value ? defaultValue : +value
}

export function getStringFromQueryString(urlParams: URLSearchParams, paramName: string, defaultValue = ''): string {
  return decodeURIComponent(urlParams.get(paramName) ?? defaultValue)
}

export const serializeParameter = (params: Record<string, string | number | string[] | number[]> | undefined) => {
  const searchParams = new URLSearchParams()
  if (params)
    Object.keys(params).forEach((key) => {
      const v = params[key]
      if (v.toString()) searchParams.append(key, v.toString())
    })
  return searchParams.toString()
}

const instance = axios.create({
  baseURL: process.env.REACT_APP_MERCHANT_API_URL,
  headers: { Authorization: `Bearer ${getUserToken()}` },
})

export const interceptError = (setError: (error: AxiosError) => void) => {
  instance.interceptors.response.use(
    (response) => {
      return response
    },
    (error: AxiosError) => {
      reportErrorToServer(error)
      setError(error)
      return error
    },
  )
}

export { instance as apiClient }
