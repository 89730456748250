import { Tooltip, Button, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import AddTerritoryDialog from '@src/containers/BrowseTerritories/components/AddTerritoryDialog'

import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded'

const MainActions = () => {
  const { t } = useTranslation()
  const [openAddTerritoryDialog, setOpenAddTerritoryDialog] = useState(false)
  const handleAdd = () => {
    setOpenAddTerritoryDialog(true)
  }
  const handleCloseProfile = () => {
    setOpenAddTerritoryDialog(false)
  }
  return (
    <Stack direction="row">
      <Tooltip title={t('common.addTerritory')}>
        <Button variant="contained" color="primary" onClick={handleAdd} style={{ marginBottom: '20px' }}>
          <AddCircleOutlineRoundedIcon />
        </Button>
      </Tooltip>
      <AddTerritoryDialog
        open={openAddTerritoryDialog}
        handleClose={handleCloseProfile}
        title={t('common.newTerritory')}
      />
    </Stack>
  )
}
export default MainActions
