import { ContentCopy as ContentCopyIcon } from '@mui/icons-material'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useDeleteUser } from '@src/data/api/merchants-api/merchants-api'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, TextField, Tooltip } from '@mui/material'
import AsyncActionButton from './AsyncActionButton'

type Props = {
  open: boolean
  handleClose: () => void
  email: string
  merchantId: string
}

const DeleteUserDialog = ({ open, handleClose, email, merchantId }: Props) => {
  const { t } = useTranslation()

  const [deleteUser, isPendingDeleteUser] = useDeleteUser()

  const [emailInput, setEmailInput] = React.useState<string>('')
  const [error, setError] = React.useState<boolean>(false)

  const closeDialog = React.useCallback(() => {
    setEmailInput('')
    setError(false)
    handleClose()
  }, [handleClose])

  const copyEmailToClipboard = React.useCallback(async () => {
    await navigator.clipboard.writeText(email)
  }, [email])

  const handleEmailInputChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailInput(event.target.value)
    setError(false)
  }, [])

  const handleDeleteClick = React.useCallback(async () => {
    if (emailInput === email) {
      await deleteUser({ email, merchantId }).then(() => {
        closeDialog()
      })
    } else {
      setError(true)
    }
  }, [emailInput, email, deleteUser, merchantId, closeDialog])

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="md">
      <DialogTitle>
        {t('deleteDialog.dialogTitle')}
        <Tooltip title={t('deleteDialog.copy')}>
          <Box style={{ color: 'red', cursor: 'pointer', display: 'inline' }} onClick={copyEmailToClipboard}>
            {email} <ContentCopyIcon />
          </Box>
        </Tooltip>
      </DialogTitle>

      <DialogContent>
        <DialogContentText>{t('deleteDialog.confirmDeletionMessage')}</DialogContentText>

        <TextField
          autoFocus
          required
          margin="dense"
          label={t('common.email')}
          type="email"
          fullWidth
          variant="standard"
          onChange={handleEmailInputChange}
          value={emailInput}
          error={error}
          helperText={error && t('deleteDialog.errorMessage')}
          placeholder={email}
        />
      </DialogContent>

      <DialogActions>
        <AsyncActionButton onClick={closeDialog} fullWidth={false}>
          {t('common.cancel')}
        </AsyncActionButton>

        <AsyncActionButton
          isPending={isPendingDeleteUser}
          variant="contained"
          color="error"
          onClick={handleDeleteClick}
          fullWidth={false}
        >
          {t('common.delete')}
        </AsyncActionButton>
      </DialogActions>
    </Dialog>
  )
}

export default DeleteUserDialog
