import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Alert } from '@mui/material'
import { AsyncActionButton } from '@src/components'
import { useCreateTerritory, useTerritoriesList } from '@src/data/api/territories-api/territories-api'
import { useForm } from 'react-hook-form'
import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Territory } from '@src/data/types/Territory'

type Props = {
  open: boolean
  title: string
  handleClose: () => void
}

const AddTerritoryDialog = ({ open, handleClose, title }: Props) => {
  const { t } = useTranslation()
  const [createTerritory, isCreatingTerritory, resetCreateTerritory] = useCreateTerritory()
  const [territoriesList] = useTerritoriesList()
  const [nameExists, setNameExists] = useState(false)

  const { handleSubmit, reset, register } = useForm<Territory>({
    mode: 'onBlur',
  })

  useEffect(() => {
    reset()
    setNameExists(false)
  }, [open, reset])

  const onSubmit = async (data: Territory) => {
    setNameExists(territoriesList.some((territory) => territory.name === data.name))
    if (nameExists) {
      return
    }
    await createTerritory(data).then((value) => {
      resetCreateTerritory()
      if (value) handleClose()
    })
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose()
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      transitionDuration={0}
    >
      <DialogTitle style={{ borderBottom: '1px solid' }} id="alert-dialog-title" sx={{ textAlign: 'center' }}>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose()
          }}
          style={{
            position: 'absolute',
            right: 15,
            top: 15,
          }}
        >
          <CloseIcon />
        </IconButton>
        {title}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }} alignItems="flex-start">
          <Grid item xs={12} md={12}>
            <TextField id="name" label={t('common.name')} fullWidth {...register('name')} />
          </Grid>
        </Grid>
      </DialogContent>
      {nameExists && (
        <Alert severity="error" style={{ margin: 0 }}>
          {t('browseTerritories.mainActions.territoryNameExists')}
        </Alert>
      )}
      <DialogActions>
        <Grid container spacing={1} justifyContent="center" textAlign="center">
          <Grid item xs={12} md={12}>
            <AsyncActionButton
              fullWidth
              variant="outlined"
              isPending={isCreatingTerritory}
              onClick={async () => {
                await handleSubmit(onSubmit)()
              }}
            >
              {t('common.addTerritory')}
            </AsyncActionButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(AddTerritoryDialog)
