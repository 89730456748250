import { MerchantFilter } from '@src/data/types/Filter'
import { MerchantDashboardUser } from '@src/data/types/MerchantDashboardUserSchema'
import { UpdateParentDto } from '@src/data/types/UpdateParentDto'
import { BannerDto } from '@src/data/types/common/BannerSchema'
import { serializeParameter } from '@src/services/http-common'
import { MutationFunction, QueryFunctionContext, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Merchant } from '../../types/Merchant'
import { getApiClient } from '../api-client'

const SCOPEMERCHANTS = 'merchants'
const SCOPEBANNERS = 'banners'
const SCOPETERRITORIES = 'territories'
const LIST = 'list'
const DETAIL = 'detail'
const USERS = 'users'

const keysFactory = {
  allMerchants: () => [{ scope: SCOPEMERCHANTS }] as const,
  allMerchantsLists: () => [{ scope: SCOPEMERCHANTS, entity: LIST }] as const,
  merchantsList: (filters: MerchantFilter) => [{ scope: SCOPEMERCHANTS, entity: LIST, ...filters }] as const,
  allMerchantsDetails: () => [{ scope: SCOPEMERCHANTS, entity: DETAIL }] as const,
  merchantDetail: (id: string) => [{ scope: SCOPEMERCHANTS, entity: DETAIL, id }] as const,
  allMerchantsUsers: () => [{ scope: SCOPEMERCHANTS, entity: USERS }] as const,
  merchantUsers: (merchantId: string) => [{ scope: SCOPEMERCHANTS, entity: USERS, merchantId }] as const,
  allBanners: () => [{ scope: SCOPEBANNERS }] as const,
  allBannersLists: () => [{ scope: SCOPEBANNERS, entity: LIST }] as const,
  bannersList: (id: string) => [{ scope: SCOPEBANNERS, entity: LIST, id }] as const,
  allBannersDetails: () => [{ scope: SCOPEBANNERS, entity: DETAIL }] as const,
  bannerDetails: (id: string) => [{ scope: SCOPEBANNERS, entity: DETAIL, id }] as const,
  bannerMerchants: (id: string) => [{ scope: SCOPEBANNERS, entity: SCOPEMERCHANTS, id }] as const,
  territoryMerchants: (id: string) => [{ scope: SCOPETERRITORIES, entity: SCOPEMERCHANTS, id }] as const,
}

const getMerchantList = async ({
  queryKey: [filters],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['merchantsList']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<Merchant[]>('/Merchants', {
    params: filters,
    paramsSerializer(params) {
      return serializeParameter(params)
    },
  })
  return response.data
}

export function useMerchantsList(filter: MerchantFilter): [Merchant[], boolean] {
  const { data, isFetching } = useQuery({
    queryKey: [...keysFactory.merchantsList(filter)],
    queryFn: getMerchantList,
    initialData: [],
  })

  return [data, isFetching]
}

const getMerchant = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['merchantDetail']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get<Merchant>(`/Merchants/${encodeURIComponent(id)}`)
  return response.data
}

export function useMerchantById(id: string): [Merchant | null, boolean] {
  const { data, isFetching } = useQuery({
    queryKey: keysFactory.merchantDetail(id),
    queryFn: getMerchant,
    enabled: !!id,
  })

  return [data ?? null, isFetching]
}

const updateMerchantParent: MutationFunction<Merchant, UpdateParentDto> = async (dto: UpdateParentDto) => {
  const apiClient = getApiClient()
  const response = await apiClient.put(`/Merchants/${dto.merchantId}/Parent`, dto)
  return response.data as Merchant
}

export function useUpdateMerchantParent(): [MutationFunction<Merchant, UpdateParentDto>, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: updateMerchantParent,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: keysFactory.allMerchantsLists(),
      })
    },
  })

  return [mutateAsync, isPending, reset]
}

const getMerchantUsers = async ({
  queryKey: [{ merchantId }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['merchantUsers']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get(`/Merchant/${merchantId}/Users`)
  return response.data as MerchantDashboardUser[]
}

export function useMerchantUserList(merchantId: string): [MerchantDashboardUser[], boolean] {
  const { data, isFetching } = useQuery({
    queryKey: keysFactory.merchantUsers(merchantId),
    queryFn: getMerchantUsers,
    initialData: [],
  })

  return [data, isFetching]
}

const createBanner: MutationFunction<Merchant, BannerDto> = async (dto: BannerDto) => {
  const apiClient = getApiClient()
  const response = await apiClient.post('/Banners', dto)
  return response.data as Merchant
}

export function useCreateBanner(): [MutationFunction<Merchant, BannerDto>, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: createBanner,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: keysFactory.allMerchantsLists(),
      })
    },
  })

  return [mutateAsync, isPending, reset]
}

const updateBanner: MutationFunction<Merchant, BannerDto> = async (dto: BannerDto) => {
  const apiClient = getApiClient()
  const response = await apiClient.put(`/Banners/${dto.id}`, dto)
  return response.data as Merchant
}

export function useUpdateBanner(): [MutationFunction<Merchant, BannerDto>, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: updateBanner,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.bannerDetails(data.id), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

const getMerchantsByParentId = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['bannerMerchants']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.post(`/Banners/${id}/Subsidiaries`)
  return response.data as Merchant[]
}

export function useMerchantByParentId(id: string): [Merchant[], boolean] {
  const { data, isFetching } = useQuery({
    queryKey: keysFactory.bannerMerchants(id),
    queryFn: getMerchantsByParentId,
    initialData: [],
  })

  return [data, isFetching]
}

const getMerchantsByTerritoryId = async ({
  queryKey: [{ id }],
}: QueryFunctionContext<ReturnType<(typeof keysFactory)['territoryMerchants']>>) => {
  const apiClient = getApiClient()
  const response = await apiClient.get(`/Territories/${id}/Merchants`)
  return response.data as Merchant[]
}

export function useMerchantsByTerritoryId(id: string): [Merchant[], boolean] {
  const { data, isFetching } = useQuery({
    queryKey: keysFactory.territoryMerchants(id),
    queryFn: getMerchantsByTerritoryId,
    initialData: [],
  })

  return [data, isFetching]
}

interface DeleteUserParams {
  email: string
  merchantId: string
}

const deleteUser = async ({ email }: DeleteUserParams) => {
  const apiClient = getApiClient()
  await apiClient.delete(`/Users/${email}`)
}

export function useDeleteUser(): [MutationFunction<void, DeleteUserParams>, boolean] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending } = useMutation({
    mutationFn: deleteUser,
    onSuccess: (_, variables) => {
      const { email, merchantId } = variables
      const previousUsers = queryClient.getQueryData<MerchantDashboardUser[]>(keysFactory.merchantUsers(merchantId))

      if (previousUsers) {
        const updatedUsers = previousUsers.filter((user) => user.email !== email)
        queryClient.setQueryData(keysFactory.merchantUsers(merchantId), updatedUsers)
      }
    },
  })

  return [mutateAsync, isPending]
}

export interface UpdateTerritoryDto {
  merchantId: string
  territoryId: string | null
}

const updateMerchantTerritory: MutationFunction<Merchant, UpdateTerritoryDto> = async (dto: UpdateTerritoryDto) => {
  const apiClient = getApiClient()
  const response = await apiClient.put(`/Merchants/${dto.merchantId}/TerritoryId`, {
    territoryId: dto.territoryId,
  })
  return response.data as Merchant
}

export function useUpdateMerchantTerritory(): [MutationFunction<Merchant, UpdateTerritoryDto>, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: updateMerchantTerritory,
    onSuccess: (updatedMerchant: Merchant) => {
      queryClient.setQueryData(keysFactory.merchantDetail(updatedMerchant.id), (oldMerchant: Merchant | undefined) => {
        if (!oldMerchant) return updatedMerchant

        return { ...oldMerchant, territoryId: updatedMerchant.territoryId }
      })
    },
  })

  return [mutateAsync, isPending, reset]
}
