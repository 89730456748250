import { ELanguageAbr, ELanguageAbrList } from './Constants'
import * as yup from './common/yup-extended'

const merchantDashboardUserSchema = {
  firstName: yup.default
    .string()
    .matches(/^[^0-9_!¡?÷?¿/+=@#$%ˆ&*(){}|~<>;":[]*$/, 'common.errors.illegalCharacters')
    .max(49, 'common.errors.tooManyCharacters')
    .required('common.errors.required'),
  lastName: yup.default
    .string()
    .matches(/^[^0-9_!¡?÷?¿/+=@#$%ˆ&*(){}|~<>;":[]*$/, 'common.errors.illegalCharacters')
    .max(49, 'common.errors.tooManyCharacters')
    .required('common.errors.required'),
  email: yup.default
    .string()
    .email('common.errors.notValidEmail')
    .max(49, 'common.errors.tooManyCharacters')
    .required('common.errors.required'),
  merchantId: yup.default.string(),
  accountEnabled: yup.default.bool().default(true),
  merchantName: yup.default.string(),
  language: yup.default.string().required().oneOf(ELanguageAbrList).default(ELanguageAbr.French),
  isAdmin: yup.default.boolean().default(false),
  jobTitle: yup.default.string().max(49, 'common.errors.tooManyCharacters').required('common.errors.required'),
  phone: yup.default.string().IsValidCanadianPhone().nullable().notRequired(),
  fullName: yup.default.string(),
  lastConnection: yup.default.date().nullable(),
}

export const MerchantDashboardUserSchema = yup.default.object(merchantDashboardUserSchema)

export type MerchantDashboardUser = yup.default.InferType<typeof MerchantDashboardUserSchema>
