import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

const LanguagePicker = () => {
  const { t, i18n } = useTranslation()
  const targetLanguage = t('common.targetLanguage')

  const changeLanguage = () => i18n.changeLanguage(targetLanguage)

  return <Button onClick={changeLanguage}>{targetLanguage}</Button>
}

export default LanguagePicker
