import { IApplicationInsights } from '@microsoft/applicationinsights-web'

let appInsights: IApplicationInsights | null = null

export function setupTracking(ai: IApplicationInsights): void {
  appInsights = ai
}

export function reportErrorToConsole(error: unknown) {
  console.error(error)
}
export function reportErrorToServer(error: Error) {
  reportErrorToConsole(error)
  appInsights?.trackException({ error, severityLevel: 3 })
}
