import { getUserToken, loadUserFromJwtString } from '@src/data/providers/UserProvider'
import { EUserRights } from '@src/data/types/Constants'
import { AxiosError } from 'axios'
import { createContext } from 'react'

export const AppContext = createContext<{ toggleColorMode: () => void; error: AxiosError | Error | null }>({
  toggleColorMode: () => {},
  error: null,
})

export const hasUserRight = (requiredRight: EUserRights): boolean => {
  const jwtToken = getUserToken()
  const icebergUser = jwtToken ? loadUserFromJwtString(jwtToken) : null

  if (!icebergUser) {
    return false
  }

  return !!icebergUser.rights[requiredRight]
}

export default AppContext
