import { Cancel as CancelIcon, Done as DoneIcon } from '@mui/icons-material'
import {
  Alert,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { InputTextField } from '@src/components'
import { useMerchantsList } from '@src/data/api/merchants-api/merchants-api'
import { MerchantFilter } from '@src/data/types/Filter'
import { Merchant } from '@src/data/types/Merchant'
import { debounce } from 'lodash-es'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

type OnSelectSingleMerchant = (merchant: Merchant) => void
type OnSelectMultipleMerchants = (merchants: Merchant[]) => void

type Props = {
  open: boolean
  title: string
  label: string
  allowMultiple: boolean
  onConfirm: OnSelectSingleMerchant | OnSelectMultipleMerchants
  onCancel: () => void
}

const SelectMerchantsDialog = ({ onConfirm, onCancel, open, title, label, allowMultiple }: Props) => {
  const MERCHANTS_LIMIT = 25
  const { t } = useTranslation()
  const [query, setQuery] = useState<string>('')
  const [selectedMerchants, setSelectedMerchants] = useState<Merchant[]>([])

  const updateQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value)
  }
  const debounceOnChange = debounce(updateQuery, 1000)
  React.useEffect(() => {
    return () => {
      debounceOnChange.cancel()
    }
  })

  const merchantFilter: MerchantFilter = React.useMemo(() => {
    return {
      limit: 10,
      nameContains: query,
      haveNoParentId: true,
      isActive: true,
    } as MerchantFilter
  }, [query])

  const [merchantsList, isFetchingMerchantsList] = useMerchantsList(merchantFilter)

  const handleItemSelected = useCallback(
    (evt: React.MouseEvent<HTMLElement>) => {
      const itemValue = (evt as React.MouseEvent<HTMLButtonElement>).currentTarget.id
      const value = merchantsList.find((x) => x.id === itemValue)
      if (value) {
        setSelectedMerchants([value])
      }
    },
    [merchantsList],
  )

  const handleItemChecked = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const itemValue = evt.target.value
      const selectedMerchant = merchantsList.find((x) => x.id === itemValue)

      if (selectedMerchant) {
        const existingIndex = selectedMerchants.findIndex((x) => x.id === selectedMerchant.id)

        if (existingIndex !== -1) {
          setSelectedMerchants((prev) => prev.filter((x) => x.id !== itemValue))
        } else {
          setSelectedMerchants((prev) => [...prev, selectedMerchant])
        }
      }
    },
    [selectedMerchants, merchantsList],
  )

  const cancel = () => {
    setQuery('')
    setSelectedMerchants([])
    onCancel()
  }

  const confirmSelection = useCallback(() => {
    if (selectedMerchants.length > 0) {
      if (allowMultiple) {
        const callback = onConfirm as OnSelectMultipleMerchants
        callback(selectedMerchants)
      } else {
        const callback = onConfirm as OnSelectSingleMerchant
        callback(selectedMerchants[0])
      }
      setQuery('')
      setSelectedMerchants([])
    }
  }, [allowMultiple, onConfirm, selectedMerchants])

  return (
    <Dialog
      onClose={onCancel}
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xl"
      keepMounted
      transitionDuration={0}
      disablePortal
    >
      <DialogTitle style={{ borderBottom: '1px solid' }} id="alert-dialog-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <Grid item container spacing={2} sx={{ mt: 5, width: 600 }}>
          <Grid item xs={12} md={14}>
            <InputTextField id="queryField" onChange={debounceOnChange} label={label} />
          </Grid>
          <Grid item xs={12} md={14}>
            {isFetchingMerchantsList ? (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
              </div>
            ) : (
              <div>
                {query && query.trim() && merchantsList.length === 0 && (
                  <Alert severity="info">{t('common.searchYieldsNoResults')}</Alert>
                )}
                {query && merchantsList.length >= MERCHANTS_LIMIT && (
                  <Alert severity="info">{t('common.tooManyResultsMessage', { limit: MERCHANTS_LIMIT })}</Alert>
                )}
                {(!query || !query.trim()) && <p>{t('common.typeToStartSearch')}</p>}
                {query.length > 0 && (
                  <List
                    sx={{
                      width: '100%',
                      maxWidth: 800,
                      bgcolor: 'background.paper',
                      position: 'relative',
                      overflow: 'auto',
                      height: 300,
                      '& ul': { padding: 0 },
                    }}
                  >
                    {!allowMultiple &&
                      merchantsList.map((merchant) => {
                        return (
                          <ListItemButton
                            id={merchant.id}
                            key={merchant.id}
                            selected={selectedMerchants.find((x) => x.id === merchant.id) !== undefined}
                            onClick={handleItemSelected}
                          >
                            <ListItemText>{merchant.name}</ListItemText>
                          </ListItemButton>
                        )
                      })}
                    {allowMultiple &&
                      merchantsList.map((merchant) => (
                        <ListItem key={merchant.id}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedMerchants.find((x) => x.id === merchant.id) !== undefined}
                                onChange={handleItemChecked}
                                value={merchant.id}
                              />
                            }
                            label={merchant.name}
                          />
                        </ListItem>
                      ))}
                  </List>
                )}
              </div>
            )}
          </Grid>
          {allowMultiple && (
            <Grid item container spacing={1} sx={{ mt: 1, width: 600 }}>
              {selectedMerchants.map((merchant) => (
                <Grid item key={merchant.id}>
                  <Chip label={merchant.name} size="small" />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="warning" startIcon={<CancelIcon />} onClick={() => cancel()}>
          {t('common.cancel')}
        </Button>
        <Button variant="contained" color="success" startIcon={<DoneIcon />} onClick={() => confirmSelection()}>
          {t('common.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default React.memo(SelectMerchantsDialog)
