import { Stack, Typography } from '@mui/material'
import { Address } from '@src/data/types/Address'
import { Merchant } from '@src/data/types/Merchant'
import { formatAddress } from '@src/services/strings'
import { useTranslation } from 'react-i18next'

type Props = {
  merchant: Merchant
}

const BannerInformation = ({ merchant }: Props) => {
  const { t } = useTranslation()

  const address: Address = {
    stateIso: merchant?.province,
    city: merchant?.city,
    country: merchant?.country,
    postalCode: merchant?.zipCode,
    street: merchant?.address,
  } as Address

  if (!merchant) return null

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} padding={2}>
      <Typography variant="h6" component="div">
        {merchant.name}
        <Typography variant="body1" component="div">
          {formatAddress(address)}
        </Typography>
      </Typography>
      <Typography variant="body1" component="div" sx={{ border: '1px solid', borderRadius: 2, p: 1 }}>
        {t('common.program')} :{' '}
        {merchant.supportedFinancingPrograms.map((e) => t(`common.eFinancingProgram.${e}`)).join(', ')}
      </Typography>

      <Typography
        variant="body1"
        component="div"
        sx={{ border: '2px solid', borderRadius: 2, p: 1, mb: 1, borderColor: 'error.main', float: 'right' }}
      >
        {t(`common.eLanguageAbbreviation.${merchant.languageId}`)} | {merchant.province}
      </Typography>
    </Stack>
  )
}

export default BannerInformation
