import { Address } from '@src/data/types/Address'

export const CopyMessage = (text: string) => navigator.clipboard.writeText(text)

export const formatAddress = (address: Address | null | undefined) => {
  if (!address) return 'N/A'
  let ret = ''
  if (address.civicNumber) ret = ret.concat(address.civicNumber)
  if (address.street) ret = ret.concat(` ${address.street}`)
  if (address.city) ret = ret.concat(`, ${address.city}`)
  if (address.stateIso) ret = ret.concat(`, ${address.stateIso}`)
  if (address.country) ret = ret.concat(`, ${address.country}`)
  if (address.postalCode) ret = ret.concat(`, ${address.postalCode?.toUpperCase()}`)
  return ret
}
