import { yupResolver } from '@hookform/resolvers/yup'
import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem, TextField } from '@mui/material'
import { AsyncActionButton, InputTextFieldWithMask } from '@src/components'
import { useCreateBanner } from '@src/data/api/merchants-api/merchants-api'
import { BannerDto, BannerSchema } from '@src/data/types/common/BannerSchema'
import { EProvinceList, EServiceCategoryList } from '@src/data/types/Constants'
import translate from '@src/services/translate'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type Props = {
  open: boolean
  title: string
  handleClose: () => void
}

const AddBannerDialog = ({ open, handleClose, title }: Props) => {
  const { t } = useTranslation()

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<BannerDto>({
    mode: 'onBlur',
    resolver: yupResolver(BannerSchema),
  })

  const [createBanner, isCreatingBanner, resetCreateBanner] = useCreateBanner()

  React.useEffect(() => {
    reset()
  }, [open, reset])

  const onSubmit = async (data: BannerDto) => {
    await createBanner(data).then((value) => {
      resetCreateBanner()
      if (value) handleClose()
    })
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose()
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      transitionDuration={0}
    >
      <DialogTitle style={{ borderBottom: '1px solid' }} id="alert-dialog-title" sx={{ textAlign: 'center' }}>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose()
          }}
          style={{
            position: 'absolute',
            right: 15,
            top: 15,
          }}
        >
          <CloseIcon />
        </IconButton>
        {title}
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }} alignItems="flex-start">
          <Grid item xs={12} md={6}>
            <TextField
              id="name"
              label={t('common.name')}
              fullWidth
              {...register('name')}
              error={!!errors.name}
              helperText={errors?.name?.message ? translate(errors.name.message) : ''}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="serviceCategory"
              select
              label={t('common.serviceCategory')}
              {...register('serviceCategory')}
              error={!!errors.serviceCategory}
              helperText={errors?.serviceCategory?.message ? translate(errors.serviceCategory.message) : ''}
              defaultValue=""
              fullWidth
            >
              {EServiceCategoryList.map((key) => (
                <MenuItem key={key} value={key}>
                  {t(`eServiceCategory.${key}`)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              label={t('common.email')}
              fullWidth
              {...register('email')}
              error={!!errors.email}
              helperText={errors?.email?.message ? translate(errors.email.message) : ''}
              inputProps={{ maxLength: 256 }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="phone"
              control={control}
              render={({ field }) => (
                <InputTextFieldWithMask
                  mask="000-000-0000"
                  error={errors?.phone}
                  helperText={errors?.phone?.message ? translate(errors.phone.message) : ''}
                  label={t('common.phone')}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="website"
              label={t('common.website')}
              fullWidth
              {...register('website')}
              error={!!errors.website}
              helperText={errors?.website?.message ? translate(errors.website.message) : ''}
              inputProps={{ maxLength: 256 }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              id="language"
              label={t('common.language')}
              {...register('languageId')}
              defaultValue="en"
              select
              error={!!errors.languageId}
              helperText={errors?.languageId?.message ? translate(errors.languageId.message) : ''}
              fullWidth
            >
              <MenuItem value="0">{t('common.languageId.0')}</MenuItem>
              <MenuItem value="1">{t('common.languageId.1')}</MenuItem>
            </TextField>
          </Grid>

          <Grid item xs={12} md={12}>
            <TextField
              label={t('common.address')}
              fullWidth
              {...register('address')}
              error={!!errors.address}
              helperText={errors?.address?.message ? translate(errors.address.message) : ''}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              label={t('common.city')}
              {...register('city')}
              error={!!errors.city}
              helperText={errors?.city?.message ? translate(errors.city.message) : ''}
              inputProps={{ maxLength: 50 }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              select
              label={t('common.province')}
              {...register('province')}
              defaultValue=""
              error={!!errors.province}
              helperText={errors?.province?.message ? translate(errors.province.message) : ''}
              fullWidth
            >
              {EProvinceList.map((key) => (
                <MenuItem key={key} value={key}>
                  {t(`eProvince.${key}`)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} md={4}>
            <TextField
              id="postalCode"
              label={t('common.postalCode')}
              {...register('postalCode')}
              error={!!errors.postalCode}
              helperText={errors?.postalCode?.message ? translate(errors.postalCode.message) : ''}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1} justifyContent="center" textAlign="center">
          <Grid item xs={12} md={6}>
            <AsyncActionButton
              fullWidth
              variant="outlined"
              isPending={isCreatingBanner}
              onClick={async () => {
                await handleSubmit(onSubmit)()
              }}
            >
              {t('common.addBanner')}
            </AsyncActionButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(AddBannerDialog)
