import { Grid, MenuItem, SelectChangeEvent, TextField } from '@mui/material'
import { debounce } from 'lodash-es'
import { useTranslation } from 'react-i18next'

type Props = {
  handleSearch: (value: string) => void
  financingProgramIds: string[]
  setFinancingProgramIds: (ids: string[]) => void
  nameInitValue: string
}

const BrowseMerchantToolbar = ({ handleSearch, financingProgramIds, setFinancingProgramIds, nameInitValue }: Props) => {
  const { t } = useTranslation()
  const debounceOnChange = debounce(handleSearch, 500)

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event
    setFinancingProgramIds(typeof value === 'string' ? value.split(',') : (value as string[]))
  }

  return (
    <Grid container spacing={2} sx={{ mb: 2, marginLeft: 2, marginTop: 2 }}>
      <Grid item sm={12} xs={6} md={2}>
        <TextField
          label={t('common.search')}
          placeholder={t('common.name')}
          onChange={(e) => debounceOnChange(e.target.value)}
          defaultValue={nameInitValue}
          fullWidth
        />
      </Grid>
      <Grid item sm={12} xs={6} md={2}>
        <TextField
          label="Program"
          value={financingProgramIds}
          select
          SelectProps={{ multiple: true, onChange: handleChange }}
          fullWidth
        >
          <MenuItem value="personal">Personal</MenuItem>
          <MenuItem value="auto">Auto</MenuItem>
        </TextField>
      </Grid>
    </Grid>
  )
}

export default BrowseMerchantToolbar
