import * as yup from 'yup'
import { ESourceSystem } from '../Constants'

const bannerSchema = {
  name: yup
    .string()
    .matches(/^[^0-9_!¡?÷?¿/+=@#$%ˆ&*(){}|~<>;":[]*$/, 'common.errors.illegalCharacters')
    .max(100, 'common.errors.tooManyCharacters')
    .required('common.errors.required'),
  serviceCategory: yup.string().required('common.errors.required'),
  email: yup.string().email('common.errors.notValidEmail').max(256, 'common.errors.tooManyCharacters'),
  phone: yup
    .string()
    .matches(/^\d{3}-\d{3}-\d{4}$/, 'common.errors.phone')
    .nullable()
    .notRequired(),
  website: yup.string().url('common.errors.notValidURL').notRequired().max(256, 'common.errors.tooManyCharacters'),
  address: yup.string().notRequired().max(100, 'common.errors.tooManyCharacters'),
  city: yup.string().notRequired().max(50, 'common.errors.tooManyCharacters'),
  province: yup.string().notRequired(),
  postalCode: yup.string().notRequired(),
  languageId: yup.number().required('common.errors.required'),
  sourceSystem: yup.string().default(ESourceSystem.alis).notRequired(),
  id: yup.string().notRequired(),
}
export const BannerSchema = yup.object().shape(bannerSchema)

export type BannerDto = yup.InferType<typeof BannerSchema>
