import React from 'react'
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material'
import { Alert, Box, Tooltip } from '@mui/material'
import { useMerchantsByTerritoryId } from '@src/data/api/merchants-api/merchants-api'
import { TerritoryRow } from '@src/data/types/Territory'
import { TFunction } from 'i18next'
import IconButtonWithTooltip from '../../../components/IconButtonWithTooltip'

type ActionCellProps = {
  row: TerritoryRow
  handleEdit: (row: TerritoryRow) => void
  handleDelete: (row: TerritoryRow) => void
  t: TFunction
}

const ActionCell: React.FC<ActionCellProps> = ({ row, handleEdit, handleDelete, t }) => {
  const [merchantsByTerritory, isFetchingTerritory] = useMerchantsByTerritoryId(row.id)
  const canNotDeleteMonolithTerritory = row.sourceSystem === 'monolith'
  const canEdit = row.sourceSystem !== 'monolith'
  const canDelete = merchantsByTerritory.length <= 0
  return (
    <Box position="relative">
      <Tooltip
        title={
          !canEdit && !isFetchingTerritory ? (
            <Alert severity="error" style={{ margin: 0 }}>
              {t('browseTerritories.mainActions.cannotEditTerritory')}
            </Alert>
          ) : (
            ''
          )
        }
      >
        <span>
          <IconButtonWithTooltip
            onClick={() => handleEdit(row)}
            tooltip={t('common.editTerritory')}
            icon={<EditIcon color={canEdit ? 'primary' : 'disabled'} />}
            disabled={!canEdit}
          />
        </span>
      </Tooltip>
      <Tooltip
        title={
          canNotDeleteMonolithTerritory && !isFetchingTerritory ? (
            <Alert severity="error" style={{ margin: 0 }}>
              {t('browseTerritories.mainActions.cannotDeleteMonolithTerritory')}
            </Alert>
          ) : !canDelete ? (
            <Alert severity="error" style={{ margin: 0 }}>
              {t('browseTerritories.mainActions.cannotDeleteTerritory')}
            </Alert>
          ) : (
            ''
          )
        }
      >
        <span>
          <IconButtonWithTooltip
            onClick={() => canDelete && handleDelete(row)}
            tooltip={t('common.deleteTerritory')}
            icon={<DeleteIcon style={{ color: canDelete ? undefined : 'rgba(0, 0, 0, 0.3)' }} />}
            disabled={!canDelete}
          />
        </span>
      </Tooltip>
    </Box>
  )
}

export default ActionCell
