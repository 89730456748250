import React, { useState } from 'react'
import { Stack, Typography, TextField, CircularProgress, Alert, InputAdornment } from '@mui/material'
import { Edit as EditIcon } from '@mui/icons-material'
import { Address } from '@src/data/types/Address'
import { Merchant } from '@src/data/types/Merchant'
import { formatAddress } from '@src/services/strings'
import { useTranslation } from 'react-i18next'
import { Territory } from '@src/data/types/Territory'
import { useUpdateMerchantTerritory } from '@src/data/api/merchants-api/merchants-api'
import { useTerritoryById } from '@src/data/api/territories-api/territories-api'
import DeleteIcon from '@mui/icons-material/Delete'
import { ESourceSystem } from '@src/data/types/Constants'
import SelectTerritoryDialog from './components/SelectTerritoryDialog'
import IconButtonWithTooltip from '../../components/IconButtonWithTooltip'

type Props = {
  merchant: Merchant
}

const MerchantInformation = ({ merchant }: Props) => {
  const { t } = useTranslation()
  const territoryId = merchant ? merchant.territoryId : ''
  const [openTerritoriesDialog, setOpenTerritoriesDialog] = useState(false)
  const [updateTerritory] = useUpdateMerchantTerritory()

  const [fetchedTerritory, isFetching, error] = useTerritoryById(territoryId)

  const territoryName = fetchedTerritory?.name || t('merchant.noTerritory')

  const handleClickOpen = () => {
    setOpenTerritoriesDialog(true)
  }

  const handleClose = () => {
    setOpenTerritoriesDialog(false)
  }

  const handleTerritorySelected = async (selectedTerritory: Territory) => {
    await updateTerritory({ merchantId: merchant.id, territoryId: selectedTerritory.id })
    setOpenTerritoriesDialog(false)
  }

  const handleDeleteTerritory = async () => {
    await updateTerritory({ merchantId: merchant.id, territoryId: null })
  }

  const address: Address = {
    stateIso: merchant?.province,
    city: merchant?.city,
    country: merchant?.country,
    postalCode: merchant?.zipCode,
    street: merchant?.address,
  } as Address

  // Cannot modify Monolith merchant territory while they are managed in the Monolith
  const canDelete = merchant?.territoryId !== null && merchant?.sourceSystem === ESourceSystem.alis

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} padding={2}>
      {merchant && (
        <>
          <Typography variant="h6" component="div">
            {merchant.name}
            <Typography variant="body1" component="div">
              {formatAddress(address)}
            </Typography>
          </Typography>

          {isFetching && <CircularProgress />}
          {error && <Alert severity="error">{error.message}</Alert>}

          <TextField
            sx={{ ml: 2, mr: 2, minWidth: '20vh' }}
            label={t('common.territory')}
            value={territoryName}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <IconButtonWithTooltip
                    disabled={merchant.sourceSystem === ESourceSystem.monolith}
                    onClick={handleClickOpen}
                    tooltip={t('common.editTerritory')}
                    icon={<EditIcon fontSize="medium" style={{ cursor: 'pointer' }} />}
                  />
                  <IconButtonWithTooltip
                    onClick={() => canDelete && handleDeleteTerritory()}
                    tooltip={t('common.deleteTerritory')}
                    icon={<DeleteIcon fontSize="medium" />}
                    disabled={!canDelete}
                  />
                </InputAdornment>
              ),
            }}
          />

          <SelectTerritoryDialog
            open={openTerritoriesDialog}
            title={t('merchant.addTerritory')}
            label={t('common.territory')}
            onConfirm={handleTerritorySelected}
            onCancel={handleClose}
            sourceSystem={merchant.sourceSystem}
          />
          <Typography
            variant="body1"
            component="div"
            sx={{ border: '2px solid', borderRadius: 2, p: 1, mb: 1, borderColor: 'error.main', float: 'right' }}
          >
            {t(`common.eLanguageAbbreviation.${merchant.languageId}`)} | {merchant.province}
          </Typography>
        </>
      )}
    </Stack>
  )
}

export default MerchantInformation
