import { AppBar, Container, Toolbar, IconButton, useTheme, Link } from '@mui/material'
import {
  Brightness7 as Brightness7Icon,
  ViewList as ViewListIcon,
  Brightness4 as Brightness4Icon,
} from '@mui/icons-material'
import { useContext } from 'react'
import LanguagePicker from './LanguagePicker'
import AppMenu from './AppMenu'
import TerritoriesMenu from './TerritoriesMenu'
import ColorModeContext from '../../../contexts/appContext '
import UserMenu from './UserMenu'

type AppHeaderProps = {
  hasAlertBanner?: boolean
}

const Layout = ({ hasAlertBanner }: AppHeaderProps): JSX.Element => {
  const theme = useTheme()
  const colorMode = useContext(ColorModeContext)

  return (
    <AppBar
      position="sticky"
      sx={{ backgroundColor: 'rgb(0, 0, 89)', color: 'white', top: hasAlertBanner ? 48 : 0, mb: 10 }}
    >
      <Container maxWidth="xl" disableGutters>
        <Toolbar disableGutters>
          <div className="icebergBanner">
            <img src="/images/iceberg-no-bg.png" alt="Iceberg Finance" style={{ width: '110px', height: '40px' }} />
          </div>
          <Link href="/">
            <IconButton>
              <ViewListIcon sx={{ color: '#ffffff' }} />
            </IconButton>
          </Link>
          <span style={{ flexGrow: 1 }}>&nbsp;</span>
          <AppMenu />
          <TerritoriesMenu />
          <UserMenu />
          <LanguagePicker />
          <IconButton onClick={colorMode.toggleColorMode}>
            {theme.palette.mode === 'dark' ? (
              <Brightness7Icon sx={{ color: '#ffffff' }} />
            ) : (
              <Brightness4Icon sx={{ color: '#ffffff' }} />
            )}
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
Layout.defaultProps = {
  hasAlertBanner: false,
}
export default Layout
