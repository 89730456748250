import { useMerchantById, useMerchantUserList } from '@src/data/api/merchants-api/merchants-api'
import { Merchant } from '@src/data/types/Merchant'
import { useParams } from 'react-router-dom'
import Users from '../../components/userList'
import MerchantInformation from './merchantInformation'

const MerchantPage = () => {
  const { id } = useParams()

  const [users, isFetchingUsers] = useMerchantUserList(id ?? '')
  const [merchant] = useMerchantById(id ?? '')

  return (
    <>
      <MerchantInformation merchant={merchant as Merchant} />
      <Users merchantId={id as string} usersList={users} isPendingSearchUsers={isFetchingUsers} />
    </>
  )
}

export default MerchantPage
