import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const TerritoriesMenu = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div>
      <Button id="btnTerritories" onClick={() => navigate('/territories', { replace: true })}>
        {t('common.territories')}
      </Button>
    </div>
  )
}
export default TerritoriesMenu
