import { MerchantDashboardUser } from '@src/data/types/MerchantDashboardUserSchema'
import { reportErrorToConsole } from '@src/services/error-logger'
import { MutationFunction, QueryFunctionContext, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { getApiClient } from '../api-client'

export type JwtToken = {
  jwt: string
}

export async function signIn(data: JwtToken): Promise<JwtToken> {
  const cApiClient = getApiClient()
  return cApiClient
    .post('/GenerateJwtToken', data)
    .then((response) => {
      return response.data as JwtToken
    })
    .catch((error) => {
      reportErrorToConsole(error)
      return {} as JwtToken
    })
}

const SCOPE = 'user-profile'
const LIST = 'list'
const DETAIL = 'detail'

const keysFactory = {
  all: () => [{ scope: SCOPE }] as const,
  allLists: () => [{ scope: SCOPE, entity: LIST }] as const,
  list: (id: string) => [{ scope: SCOPE, entity: LIST, id }] as const,
  allDetails: () => [{ scope: SCOPE, entity: DETAIL }] as const,
  detail: (id: string) => [{ scope: SCOPE, entity: DETAIL, id }] as const,
}

const createUser: MutationFunction<MerchantDashboardUser, MerchantDashboardUser> = async (
  user: MerchantDashboardUser,
) => {
  const cApiClient = getApiClient()
  const response = await cApiClient.post('/Users', user)
  return response.data as MerchantDashboardUser
}

export function useCreateUser(): [
  MutationFunction<MerchantDashboardUser, MerchantDashboardUser>,
  boolean,
  () => void,
  Error | null,
] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset, error } = useMutation({
    mutationFn: createUser,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: keysFactory.allLists(),
      })
    },
  })

  return [mutateAsync, isPending, reset, error]
}

const updateUser: MutationFunction<MerchantDashboardUser, MerchantDashboardUser> = async (
  user: MerchantDashboardUser,
) => {
  const cApiClient = getApiClient()
  const response = await cApiClient.put(`/Users/${user.email}`, user)
  return response.data as MerchantDashboardUser
}

export function useUpdateUser(): [MutationFunction<MerchantDashboardUser, MerchantDashboardUser>, boolean, () => void] {
  const queryClient = useQueryClient()
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: updateUser,
    onSuccess: (data) => {
      queryClient.setQueryData(keysFactory.detail(data.email), data)
      return data
    },
  })

  return [mutateAsync, isPending, reset]
}

const resetPassword: MutationFunction<string, string> = async (email: string) => {
  const cApiClient = getApiClient()
  const response = await cApiClient.post(`/Users/${email}/ResetPassword`)
  return response.data as string
}

export function useResetPassword(): [MutationFunction<string, string>, boolean, () => void] {
  const { mutateAsync, isPending, reset } = useMutation({
    mutationFn: resetPassword,
  })

  return [mutateAsync, isPending, reset]
}

const getUser = async ({ queryKey: [{ id }] }: QueryFunctionContext<ReturnType<(typeof keysFactory)['detail']>>) => {
  const cApiClient = getApiClient()
  const response = await cApiClient.get(`/Users/${id}`)
  return response.data as MerchantDashboardUser
}

export function useGetUser(id: string): [MerchantDashboardUser | null, boolean] {
  const { data, isFetching } = useQuery({
    queryKey: keysFactory.detail(id),
    queryFn: getUser,
    enabled: !!id,
  })

  return [data ?? null, isFetching]
}
