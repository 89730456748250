import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { getUserToken } from '../../data/providers/UserProvider'
import { JwtToken } from '../../data/api/user-api/user-api'

const AuthPage = (): JSX.Element => {
  const navigate = useNavigate()
  const token: JwtToken = { jwt: getUserToken() }
  useEffect(() => {
    navigate('/')
  }, [navigate, token.jwt])

  return <div />
}

export default AuthPage
