import { Box, CardContent, Tab } from '@mui/material'
import { DataGrid, GridPaginationModel, GridColDef, GridRenderCellParams, FooterPropsOverrides } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import React, { useState, useMemo, useCallback } from 'react'
import { useTerritoriesList, useDeleteTerritory } from '@src/data/api/territories-api/territories-api'
import { TerritoryRow } from '@src/data/types/Territory'
import { DataGridFooter } from '../../components'
import ActionCell from './components/ActionCell'
import MainActions from './MainActions'

import EditTerritoryDialog from './components/EditTerritoryDialog'
import BrowseTerritoryToolbar from './components/BrowseTerritoryToolbar'

const Territories = (): JSX.Element => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 15,
  })

  const [openEditTerritoryDialog, setOpenEditTerritoryDialog] = useState(false)
  const [selectedRow, setSelectedRow] = useState<TerritoryRow | null>(null)
  const [territoriesList] = useTerritoriesList()
  const [deleteTerritory] = useDeleteTerritory()
  const [selectedFinancingCompanies, setSelectedFinancingCompagnies] = useState<string[]>(['alis'])
  const handleEdit = useCallback((row: TerritoryRow) => {
    setSelectedRow(row)
    setOpenEditTerritoryDialog(true)
  }, [])

  const handleCloseProfile = useCallback(() => {
    setOpenEditTerritoryDialog(false)
  }, [])

  const handleDelete = useCallback(
    async (row: TerritoryRow) => {
      await deleteTerritory(row.id)
    },
    [deleteTerritory],
  )

  const columns = useMemo<GridColDef<TerritoryRow>[]>(
    (): GridColDef<TerritoryRow>[] => [
      {
        field: 'name',
        headerName: t('common.fullName'),
        flex: 1,
      },
      {
        field: 'actions',
        headerName: t('common.actions'),
        renderCell: (params: GridRenderCellParams<TerritoryRow>) => (
          <ActionCell row={params.row} handleEdit={handleEdit} handleDelete={handleDelete} t={t} />
        ),
        sortable: false,
        width: 150,
      },
    ],
    [t, handleEdit, handleDelete],
  )

  const handleFinancingCompaniesChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedFinancingCompagnies(event.target.value as string[])
  }
  const filteredTerritories = useMemo(() => {
    const start = pagination.page * pagination.pageSize
    const end = start + pagination.pageSize
    return territoriesList
      .filter((territory) => selectedFinancingCompanies.includes(territory.sourceSystem))
      .slice(start, end)
  }, [territoriesList, selectedFinancingCompanies, pagination.page, pagination.pageSize])

  const totalRowCount = territoriesList.filter((territory) =>
    selectedFinancingCompanies.includes(territory.sourceSystem),
  ).length

  const isPreviewDisabled = pagination.page === 0
  const isNextDisabled = (pagination.page + 1) * pagination.pageSize >= totalRowCount

  return (
    <>
      <Box sx={{ textAlign: 'center', height: '45rem' }}>
        <BrowseTerritoryToolbar
          selectedFinancingCompanies={selectedFinancingCompanies}
          handleChange={handleFinancingCompaniesChange}
        />
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '30px' }}>
            <Tab label={t('common.territories')} />
            <MainActions />
          </div>
          <DataGrid
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 15,
                },
              },
            }}
            onPaginationModelChange={(model: GridPaginationModel) => setPagination(model)}
            pageSizeOptions={[15, 25, 50, 100]}
            disableColumnFilter
            paginationMode="client"
            rowCount={totalRowCount}
            rows={filteredTerritories}
            columns={columns}
            disableRowSelectionOnClick
            slots={{
              footer: DataGridFooter,
            }}
            slotProps={{
              footer: {
                disabledPreview: isPreviewDisabled,
                disabledNext: isNextDisabled,
                pagination,
                setPagination,
              } as FooterPropsOverrides,
            }}
          />
        </CardContent>
      </Box>
      <EditTerritoryDialog
        open={openEditTerritoryDialog}
        handleClose={handleCloseProfile}
        title={selectedRow ? selectedRow.name : t('common.newTerritory')}
        row={selectedRow}
      />
    </>
  )
}
export default Territories
