import React from 'react'
import { Grid, MenuItem, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'

type Props = {
  selectedFinancingCompanies: string[]
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void
}

const BrowseTerritoryToolbar = ({ selectedFinancingCompanies, handleChange }: Props) => {
  const { t } = useTranslation()
  return (
    <Grid container sx={{ margin: 2 }}>
      <Grid item sm={12} xs={6} md={2}>
        <TextField
          label={t('browseTerritories.financingCompany')}
          select
          value={selectedFinancingCompanies}
          onChange={handleChange}
          SelectProps={{
            multiple: true,
          }}
          fullWidth
        >
          <MenuItem value="monolith">Monolith</MenuItem>
          <MenuItem value="alis">IFinance</MenuItem>
        </TextField>
      </Grid>
    </Grid>
  )
}

export default BrowseTerritoryToolbar
