import { useAccount, useMsal } from '@azure/msal-react'
import { Button, Menu, MenuItem } from '@mui/material'
import { getUserToken, loadUserFromJwtString } from '@src/data/providers/UserProvider'
import React from 'react'
import { useTranslation } from 'react-i18next'

const UserMenu = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const menuStyle = { textDecoration: 'none' }
  const { t } = useTranslation()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const jwtToken = getUserToken()
  const user = jwtToken ? loadUserFromJwtString(jwtToken) : null

  const { instance } = useMsal()
  const account = useAccount()

  return (
    <div>
      <Button
        id="btnUser"
        aria-controls={open ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {user?.name}
      </Button>
      <Menu id="user-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          style={menuStyle}
          onClick={async () => {
            localStorage.clear()
            if (account) await instance.logoutRedirect({ account })
            else window.location.reload()
          }}
        >
          {t('common.logout')}
        </MenuItem>
      </Menu>
    </div>
  )
}

export default UserMenu
