import { Navigate, Route, Routes } from 'react-router-dom'
import AuthPage from '../Authentication'
import Home from '../Home/HomePage'
import LoginPage from '../Home/LoginPage'
import ViewMerchant from '../ViewMerchant'
import BannerMerchant from '../ViewBanner'
import Territories from '../BrowseTerritories'

const TopLevelRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/merchant/browse" replace />} />
      <Route path="/merchant/browse" element={<Home />} />
      <Route path="home" element={<Home />} />
      <Route path="/territories" element={<Territories />} />
      <Route path="login" element={<LoginPage />} />
      <Route path="auth-page" element={<AuthPage />} />
      <Route path="/merchant/:id/view" element={<ViewMerchant />} />
      <Route path="/banner/:id/view" element={<BannerMerchant />} />
    </Routes>
  )
}

export default TopLevelRoutes
