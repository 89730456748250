import { TextField, StandardTextFieldProps } from '@mui/material'
import React from 'react'
import { FieldError } from 'react-hook-form'
import translate from '@src/services/translate'

interface Props extends Omit<StandardTextFieldProps, 'error'> {
  error?: FieldError
}

const InputTextField = React.forwardRef<Props, Props>(function InputTextField({ error, ...othersProps }, ref) {
  return (
    <TextField
      {...othersProps}
      inputRef={ref}
      error={error !== undefined}
      helperText={translate(error?.message ?? '')}
      variant="outlined"
      fullWidth
      InputLabelProps={{ shrink: true }}
    />
  )
})

InputTextField.defaultProps = {
  error: undefined,
}

export default InputTextField
