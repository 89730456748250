import { useMemo } from 'react'
import { useTerritoriesList } from '@src/data/api/territories-api/territories-api'
import { Territory } from '@src/data/types/Territory'
import { Merchant } from '../types/Merchant'

const useTerritoryDictionaryById = (merchantsList: Merchant[]): [Record<string, string>, boolean] => {
  const [territoriesList, isLoading] = useTerritoriesList()

  return useMemo(() => {
    const territories: Record<string, string> = {}
    if (territoriesList && merchantsList.length) {
      territoriesList.forEach((territory: Territory) => {
        territories[territory.id] = territory.name
      })
    }
    return [territories, isLoading]
  }, [territoriesList, isLoading, merchantsList])
}

export default useTerritoryDictionaryById
